import posthog, { Properties } from 'posthog-js'

let isPostHogInitialized = false;

export default function usePosthog() {
  if (import.meta.env.VITE_POSTHOG_PUBLIC_KEY) {
    posthog.init(import.meta.env.VITE_POSTHOG_PUBLIC_KEY, {
      api_host: 'https://eu.i.posthog.com',
      capture_pageview: false
    });
    isPostHogInitialized = true;
  }

  function safeCapture(eventName: string, properties: Properties | null | undefined = null) {
    if (isPostHogInitialized) {
      posthog.capture(eventName, properties);
    } else {
      console.warn('PostHog is not initialized. Event not captured:', eventName);
    }
  }

  return {
    posthog,
    capture: safeCapture
  };
}