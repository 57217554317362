import { createRouter, createWebHistory } from 'vue-router'
import { default as routes, registerRouterMethods } from './routes'
import usePostHog from '@hexagon/common/use/use-posthog'

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})
registerRouterMethods(router)

const { posthog } = usePostHog()

router.afterEach(() => {
  posthog.capture('$pageview')
})

router.beforeEach((to, from) => {
  if (from.path !== to.path) {
    posthog.capture('$pageleave')
  }
})

export default router
